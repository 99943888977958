<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="12" class="d-flex align-items-center forgetpassword-bg">
        <!-- Left Circle-->
        <div class="circle-orange"/>
        <!-- /Left Circle-->

        <!-- Centered Text-->
        <div class="d-flex flex-column align-items-center mx-auto">
          <b-img fluid :src="logoImg" alt="logo" class="logo"/>

          <!-- Forgot Password Form (stag 1)-->
          <SendEmailCode v-if="stage === 1" @changeFirstStageData="changeFirstStageData"/>
          <!-- /Forgot Password Form (stag 1)-->

          <!-- Forgot Password Form (stag 2)-->
          <CheckCode v-if="stage === 2" :email="form_data.email" @changeStage="changeStage"
                     @changeSecondStageData="changeSecondStageData"
          />
          <!-- /Forgot Password Form (stag 2)-->

          <!-- Forgot Password Form (stag 3)-->
          <ResetPasword v-if="stage === 3" :email="form_data.email" :pin_code="form_data.pin_code"/>
          <!-- /Forgot Password Form (stag 3)-->
        </div>
        <!-- /Centered Text-->

        <!-- Right Circle-->
        <div class="circle-mix"/>
        <!-- /Right Circle-->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BCol, BForm, BFormGroup, BFormInput, BImg, BLink, BRow } from 'bootstrap-vue'
import { required, email, min, max } from '@core/utils/validations/validations'
import SendEmailCode from '@/components/forgetPassword/sendEmailCode/SendEmailCode.vue'
import CheckCode from '@/components/forgetPassword/checkCode/CheckCode.vue'
import ResetPasword from '@/components/forgetPassword/resetPassword/ResetPasword.vue'

export default {
  name: 'ForgetPassword',
  components: {
    SendEmailCode,
    CheckCode,
    ResetPasword,
    BLink,
    BForm,
    BImg,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/futuer-icons/logo.svg'),
      stage: 1,
      required,
      min,
      max,
      errors: [],
      form_data: {
        email: '',
        pin_code: null,
      },
    }
  },
  methods: {
    changeStage(stage) {
      this.stage = stage
    },
    changeFirstStageData(email) {
      this.stage = 2
      this.form_data.email = email
    },
    changeSecondStageData(pin_code) {
      this.stage = 3
      this.form_data.pin_code = pin_code
    },
  }
}
</script>

<style lang="scss" src="./_forgetPassword.scss" scoped/>
